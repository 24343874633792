import { baseUrl } from '@/utils/env'

/**
 * Generates a link to the invoice PDF for a given set of reservation hashes.
 *
 * @param reservationHashes - The reservation hashes to generate the link for.
 * @returns The link to the invoice PDF.
 */
export const generateInvoiceLinkByHashes = (
  reservationHashes: string
): string => {
  reservationHashes = reservationHashes.substring(
    0,
    reservationHashes.length - 1
  )
  return `https://${baseUrl(
    'pdf'
  )}/pdf/charterup-invoice/reservations?reservationHashes=${reservationHashes}`
}
