var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.providerDetail && !_vm.isBronze)?_c('v-container',{staticClass:"padding-a-0"},[(_vm.$vuetify.breakpoint.xs)?_c('v-row',{staticClass:"margin-b-n4",attrs:{"align":"center","no-gutters":"","justify":"space-between"}},[_c('v-col',[_c('v-btn',{attrs:{"icon":"","color":"white","to":{
          name: 'quote-detail',
          params: { id: _vm.$route.params.quoteId },
        }}},[_c('CUIcon',{attrs:{"small":"","left":"","color":"gray","view-box":"0 0 24 24"}},[_vm._v(" chevron_left ")])],1)],1),(_vm.$vuetify.breakpoint.xs)?_c('a',{staticClass:"text-body-1 margin-r-4",attrs:{"id":`quote-detail-link-contact-support-${_vm.$vuetify.breakpoint.xs}`},on:{"click":function($event){$event.stopPropagation();return _vm.support.open({
          reservationId: null,
          quoteId: _vm.quote.quoteId,
        })}}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.helpAndSupport.CONTACT_SUPPORT'))+" ")]):_vm._e()],1):_vm._e(),_c('StickyColumnLayout',{scopedSlots:_vm._u([{key:"left-column",fn:function(){return [_c('ProviderInfo',{attrs:{"provider-detail":_vm.providerDetail}})]},proxy:true},{key:"right-column",fn:function(){return [_c('div',{staticClass:"d-flex w-full justify-end align-center"},[(_vm.loaded)?_c('a',{staticClass:"margin-r-4 font-bold",attrs:{"id":`quote-detail-link-contact-support-${_vm.$vuetify.breakpoint.smAndUp}`},on:{"click":function($event){$event.stopPropagation();return _vm.support.open({
              reservationId: null,
              quoteId: _vm.quote.quoteId,
            })}}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.helpAndSupport.CONTACT_SUPPORT'))+" ")]):_vm._e(),(_vm.showShareQuoteDialog)?_c('ShareQuoteDialog',{attrs:{"quote-id":_vm.quote.quoteId}}):_vm._e()],1),_c('BillingSummary',_vm._b({class:{
          'margin-t-7': _vm.showShareQuoteDialog,
          'margin-t-17': !_vm.showShareQuoteDialog,
        },attrs:{"button":"","checkout-detail":_vm.checkoutDetail,"is-elite":_vm.isElite,"effective-default-value-set":_vm.effectiveDefaultValueSet},on:{"click":function($event){return _vm.$router.push({
            name: 'checkout',
            params: {
              quoteId: _vm.$route.params.quoteId,
              providerId: _vm.$route.params.providerId,
            },
          })}}},'BillingSummary',_vm.providerDetail,false)),(_vm.providerDetail && _vm.quote && !_vm.isModePreview)?_c('QuoteCard',{attrs:{"quote":_vm.quote,"hide-map":""}}):_c('SkeletonTripCollectionCard')]},proxy:true},(!_vm.isModePreview)?{key:"bottom-portion",fn:function(){return [_c('BidCardCarousel',{attrs:{"quote-detail":_vm.quote}})]},proxy:true}:null],null,true)}),(_vm.$vuetify.breakpoint.mdAndDown)?_c('ProviderMobileFooter',{attrs:{"provider-detail":_vm.providerDetail,"show-share-quote-dialog":_vm.showShareQuoteDialog,"is-elite":_vm.isElite,"is-self-serve":_vm.isSelfServe}}):_vm._e()],1):(!_vm.loaded)?_c('ProviderInfoSkeleton'):(_vm.loaded)?_c('v-container',[_c('NotFoundLayout',{attrs:{"img":require('@/assets/images/no-quotes-graphic.png')},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Sorry, this company is unavailable for this date. ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" Click below to see companies ready to work for you! ")]},proxy:true}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"primary",attrs:{"id":"view-companies-button","dark":"","small":""},on:{"click":function($event){return _vm.$router.push({
          name: 'quote-detail',
          params: { id: _vm.$route.params.quoteId },
        })}}},[_vm._v(" View Available Comapnies ")])],1),_c('BidCardCarousel',{attrs:{"quote-detail":_vm.quote}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }